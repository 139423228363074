// store.js

import { createStore } from 'vuex';
import axios from 'axios';


export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null,
    isLoading: false,
    notifications: [],
    settings: {}
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', user == null ? null : JSON.stringify(user));
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    CLEAR_TOKEN(state) {
      state.token = null;
      localStorage.removeItem('token');
    },
    SET_LOADING(state, loadingState) {
      state.isLoading = loadingState
    },
    SET_NOTIFY(state, notify) {
      state.notifications.push(notify)
      setTimeout(() => {
        state.notifications.shift()
      }, 3000);
    },
    SET_SETTINGS(state, settings) {
      console.log(settings)
      if (settings !== null && Object.keys(settings).length !== 0) {
        settings.map(el => {
          state.settings[el.name] = el.intValue ? el.intValue : el.value
        })
        localStorage.setItem('settings', JSON.stringify(settings));
      } else {
        localStorage.setItem('settings', JSON.stringify({}));
      }
    }
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await axios.post('https://api.goodve.net/auth/login', {
            email,
            password,
          });
  
        const data = response.data;

        if (!data.success) {
          throw new Error('Authentication failed');
        }

        commit('SET_USER', {name: data.name, access: data.access});
        commit('SET_TOKEN', data.data.access_token);
        commit('SET_SETTINGS', data.settings);

        // После успешной аутентификации вызываем метод fetchUser для получения дополнительной информации о пользователе
        //await dispatch('fetchUser');

        return data;
      } catch (error) {
        console.error('Error during login:', error);
        throw error;
      }
    },
    async fetchUser({ commit, state }) {
      try {
        // Предположим, что ваш сервер имеет эндпоинт для получения информации о пользователе
        if (!state.token){
            throw new Error('token is not available')
        }
        const response = await axios.post('/auth/check', {access_token: state.token})

        if (!response.ok) {
          throw new Error('Failed to fetch user information');
        }

        const userData = await response.json();

        commit('SET_USER', userData);

        return userData;
      } catch (error) {
        console.error('Error during fetchUser:', error);
        throw error;
      }
    },
    logout({ commit }) {
      commit('SET_USER', null);
      commit('CLEAR_TOKEN');
    },
    setLoading({ commit }, state) {
      commit('SET_LOADING', state)
    },
    setNotify({ commit }, notify) {
      commit('SET_NOTIFY', notify)
    }
  },
  getters: {
    isAuthenticated: (state) => {
        return !!state.user && !!state.token
    },
    getUserData: (state) => {
      return state.user
    },
    getUserAccess: (state) => {
        const uniqueKeys = [...new Set(state.user.access.map(item => item.PARENT_SLUG))];

        const grouped = uniqueKeys.map(key => ({
            name: key,
            data: state.user.access.filter(item => item.PARENT_SLUG === key)
        }));
        return grouped
    },
    isLoading: (state) => {
      return state.isLoading
    },
    notifications: (state) => {
      return state.notifications
    }
  },
});
