const paths = [{
    slug: 'stories',
    title: 'Блокировки историй'
},{
    slug: 'posts',
    title: 'Блокировки постов'
}]
export default paths.map(path => {
    return {
        path: '/block/'+path.slug,
        meta: {requiresAuth: true, title: path.title},
        component: () => import('@/views/pages/blocks/'+path.slug+'Page.vue'),
    }
})