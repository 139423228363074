
const paths = [{
    slug: 'reasons',
    title: 'Причины жалоб'
},{
    slug: 'stories',
    title: 'Жалобы на сторис'
},{
    slug: 'profiles',
    title: 'Жалобы на профили'
},{
    slug: 'posts',
    title: 'Жалобы на посты'
},{
    slug: 'comments',
    title: 'Жалобы на комментарии'
}]
export default paths.map(path => {
    return {
        path: '/complaints/'+path.slug,
        component: () => import('@/views/pages/complaints/'+path.slug+'Page.vue'),
        meta: {requiresAuth: true, title: path.title}
    }
})