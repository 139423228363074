<!-- Notification.vue -->

<template>
    <div class="toast-container position-fixed top-0 end-0 p-3" v-if="notifications.length > 0">
      <div
        :class="[notify.status == 'error' ? 'text-bg-danger' : '', notify.status == 'ok' ? 'text-bg-success' :'', 'toast show']" role="alert" aria-live="assertive" aria-atomic="true" v-for="(notify, index) in notifications" :key="'notify_'+index">
        <div class="toast-header" v-if="notify.description">
          <strong class="me-auto">{{ notify.msg }}</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body" v-if="notify.description || (notify.msg && !notify.description)">{{ notify.description ? notify.description : notify.msg }}</div>
      </div>
  </div>
</template>
  
  <script>
  import { ref } from 'vue';
  import { useStore } from 'vuex'

  
  export default {
    setup() {
      const store = useStore()
      const notifications = ref([])
      notifications.value = store.getters.notifications
      return {notifications}
    },
  };
  </script>
  