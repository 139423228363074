<!-- App.vue -->

<template>
	<DataLoader />
	<NotificationItems />
	<template v-if="!user">
		<router-view/>
	</template>
    <div class="container-fluid" v-if="user">		
        <div class="row">
			<HeaderLine />
			<div class="col-lg-10 ms-sm-auto col-12 px-md-4 mt-5 mt-lg-3">
				<h1>{{ h1 }}</h1>
				<router-view/>
			</div>
        </div>
    </div>	
</template>

<script>

import { computed } from 'vue';
import { useStore } from 'vuex';
import HeaderLine from './components/HeaderLine.vue';
import DataLoader from './components/ui/DataLoader.vue';
import NotificationItems from './components/ui/NotificationItems.vue';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { useRoute } from 'vue-router';


	
	export default {
    name: 'App',
    setup() {
		const route = useRoute()
		const h1 = computed(() => {
			return route.meta.title || 'no title'
		})
        const store = useStore();
        const user = computed(() => {
            return store.state.user;
        });
        return { user, h1 };
    },
    components: { HeaderLine, DataLoader, NotificationItems }
};
</script>

<style>
body {
  margin: 0
}
* {
    box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
.card {
	background: #fff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15);
	margin-bottom: 20px;
}
.card__header {
	padding: 5px 20px;
}
.card__body {
	padding: 10px 20px;
}
.link {
	color: #007FFF;
}
.user__avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	overflow: hidden;
	background: #ddd;
}
.user__name {
	font-size: 18px;
	line-height: 20px;
	font-weight: 600;
}
.user__login {
	font-size: 14px;
	line-height: 20px;
	color: #333;
	text-decoration: none;
}
.prop {
	margin-bottom: 20px;
}
.prop__name {
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}
.media {
	display: block;
	width: 100px;
	border: 1px solid #333;
}
.drop {
	position: relative;
}
.drop__list {
	position: absolute;
	top: 100%;
	right: 0;
	width: max-content;
	max-width: 320px;
	padding: 10px 0;
	background: #fff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15);
	z-index: 10;
}
.drop__item {
	font-size: 16px;
	line-height: 20px;
	padding: 10px 20px;
	transition: 300ms;
	cursor: pointer;
}
.drop__item:hover {
	background: #ddd;
}
.out-form-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, .5);
}
.out-form {
	width: 520px;
	max-width: 100%;
	padding: 20px;
	background: #fff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15);
}
</style>
