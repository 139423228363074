<template>
    <pre>{{ tmp }}</pre>
	<div class="dropdown">
		<a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" @click="isDropdown = !isDropdown">
			<img src="https://i.pravatar.cc/32" alt="" width="32" height="32" class="rounded-circle me-2">
			<strong>{{ name }}</strong>
		</a>
		<ul :class="[isDropdown ? 'show' : '', 'dropdown-menu dropdown-menu-dark text-small shadow position-absolute']" style="bottom: 110%">
			<li v-for="(item, itemIndex) in menuItems" :key="'settingsMenu_'+itemIndex">
				<router-link 
					:to="'/'+item.PARENT_SLUG+'/'+item.SLUG"
					class="dropdown-item"
					:activeClass="'active'">
					{{item.NAME}}
				</router-link>
			</li>
			<hr>
			<li><a class="dropdown-item" href="#" @click="logoutHandler">Выйти</a></li>
		</ul>
	</div>
</template>
<script>
    import {ref} from 'vue'
	import {useStore} from 'vuex'
	
    export default {
		props: {
			menuItems: Array
		},
		setup() {
            const store = useStore()
			const isDropdown = ref(false)
			const {name} = store.getters.getUserData
            const logoutHandler = () => {
                store.dispatch('logout')
            }
			return {isDropdown, name, logoutHandler}
		}
	}
</script>