import store from "@/store"
import moment from 'moment'

const getAccessToken = () => {
    return store.state.token
}
const addNotify = (notify) => {
    store.dispatch('setNotify', notify)
}
const timestampToDate = (timestamp) => {
	return moment.unix(timestamp).format('DD.MM.YYYY HH:mm:SS')
}
const checkResponse = (res, withOk = false, okText = 'Сохранено') => {
    if (res.data === null) return []
    if (res.data.status !== 200) {
        addNotify({status: 'error', msg: res.data.error})
        console.error(res.data)
        return false
    } else {
        if (withOk === true) {
            addNotify({status: 'ok', msg: okText})
        }
        return res.data
    }
}
const complaintFilter = (list, min) => {
    console.log(min)
    let values = list.data.complaints
    values = values.filter(el => el.complaints.length >= min)
    values.sort((a, b) => b.complaints.length - a.complaints.length)
    return values
}
export {getAccessToken, addNotify, timestampToDate, checkResponse, complaintFilter}