// router.js

import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

//paths
import blocks from './paths/blocks';
import complaints from './paths/complaints';
import profiles from './paths/profiles';
import settings from './paths/settings';
import more from './paths/more';
const routes = [
  {
    path: '/',
    component: () => import('@/views/pages/LoginPage.vue'), // Установим страницу авторизации как страницу по умолчанию
  },
  {
    path: '/home',
    component: () => import('@/views/pages/HomePage.vue'),
    meta: { requiresAuth: true, title: 'Главная' },
  },
  ...blocks,
  ...complaints,
  ...profiles,
  ...settings,
  ...more
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
  //console.log(to)
  const isAuthenticated = store.getters.isAuthenticated;
	if (to.meta.requiresAuth && !isAuthenticated) {
		next('/');
	} else if (to.path === '/' && isAuthenticated) {
		// Если пользователь аутентифицирован и пытается перейти на страницу авторизации,
		// перенаправляем его на /home
		next('/home');
	} else {
		console.log(store.state)
		next();
	}
});
router.afterEach((to) => {
  document.title = to.meta.title || 'no title';
})

export default router;
