/* eslint-disable no-unused-vars */
import axios from 'axios'
import {getAccessToken, addNotify, checkResponse, complaintFilter} from '@/helpers/utils'
import store from "@/store"


const token = getAccessToken()
const authHeader = {
	headers: {
		Authorization: `Bearer ${token}`
	}
}
const settings = store.state.settings

const getPostsComplaintReasons = async() => {
    try {
        const complaintReasons = await axios.post('posts/getComplaintReasons', {}, authHeader)
        let data = checkResponse(complaintReasons)
        return data !== false ? data : false
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const getProfilesComplaintReasons = async() => {
    try {
        const complaintReasons = await axios.post('profile/getComplaintReasons', {}, authHeader)
        let data = checkResponse(complaintReasons)
        return data !== false ? data : false
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const changePostsComplaintReasons = async(reasons) => {
    try {
        const res = await axios.post('posts/changeComplaintReasons', reasons, authHeader)
        let data = checkResponse(res, true)
        return data !== false ? data : false
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const changeProfilesComplaintReasons = async(reasons) => {
    try {
        const res = await axios.post('profile/changeComplaintReasons', reasons, authHeader)
        let data = checkResponse(res, true)
        return data !== false ? data : false
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const getStoriesComplaints = async(status, storyAuthoUuid) => {
	const fields = {
		offset: 0,
		limit: 1000,
		status: parseInt(status)
	}
	if (storyAuthoUuid) {
        console.log('stiry')
		/*fields.limit = MAX_LIMIT
		fields.storyAuthor = storyAuthoUuid*/
	}
	try {
		const res = await axios.post('admin/getStoriesComplaints', fields, authHeader)
        let data = checkResponse(res)
        return data !== false ? (data.data.complaints ? data.data.complaints.sort((a, b) => a.complaints.length < b.complaints.length ? 1 : -1) : []) : false
	} catch (error) {
        addNotify({status: 'error', msg: error})
	}
}
const getPostsComplaints = async(status) => {
	const fields = {
		offset: 0,
		limit: 1000,
		status: parseInt(status)
	}
	try {
		const res = await axios.post('admin/getPostsComplaints', fields, authHeader)
        let data = checkResponse(res)
        return data !== false ? (data.data.complaints !== null ? data.data.complaints.sort((a, b) => a.complaints.length < b.complaints.length ? 1 : -1) : []) : false
	} catch (error) {
        addNotify({status: 'error', msg: error})
	}
}
const changePostComplaintStatus = async(status, uuids) => {
    try {
        const res = await axios.post('admin/changePostComplaintStatus', {
            complaintsUUID: uuids,
            status: status
        }, authHeader)
        if (res.data.status == 200) {
            addNotify({status: 'ok', msg: (status == 2 ? 'Жалобы пропущены' : 'Жалобы удалены')})
            return true
        } else {
            addNotify({status: 'error', msg: res.data.error})
            return false
        }
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const changeStoriesComplaintStatus = async(status, uuids) => {
    try {
        const res = await axios.post('admin/changeStoriesComplaintStatus', {
            complaintsUUID: uuids,
            status: status
        }, authHeader)
        if (res.data.status == 200) {
            addNotify({status: 'ok', msg: (status == 2 ? 'Жалобы пропущены' : 'Жалобы удалены')})
            return true
        } else {
            addNotify({status: 'error', msg: res.data.error})
            return false
        }
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const getUsersComplaints = async(status) => {
    let fields = {
        status: parseInt(status),
        limit: 1000,
        offset: 0
    }
    const res = await axios.post('admin/getUsersComplaints', fields, authHeader)
    let data = checkResponse(res)
    return data !== false ? (data.data.complaints !== null ? data.data.complaints.sort((a, b) => a.complaints.length < b.complaints.length ? 1 : -1) : []) : false
}
const changeProfileComplaintStatus = async(status, uuids) => {
    try {
        const res = await axios.post('admin/changeProfileComplaintStatus', {
            complaintsUUID: uuids,
            status: status
        }, authHeader)
        if (res.data.status == 200) {
            addNotify({status: 'ok', msg: (status == 2 ? 'Жалобы пропущены' : 'Жалобы удалены')})
            return true
        } else {
            addNotify({status: 'error', msg: res.data.error})
            return false
        }
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const getCommentsComplaints = async(status) => {
    try {
        console.log(settings)
        let fields = {
            limit: 1000,
            status: parseInt(status),
            offset: 0
        }
        const res = await axios.post('admin/getCommentsComplaints', fields, authHeader)
        let data = checkResponse(res)
        if (data === false) {
            return []
        } else {
            if (data.data.complaints === null) {
                return []
            } else {
                return complaintFilter(data, settings.commentsComplaintsMin ? settings.commentsComplaintsMin : 1)
            }
        }
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const changeCommentsComplaintStatus = async(status, uuids) => {
    try {
        const res = await axios.post('admin/changeCommentsComplaintStatus', {
            complaintsUUID: uuids,
            status: status
        }, authHeader)
        if (res.data.status == 200) {
            addNotify({status: 'ok', msg: (status == 2 ? 'Жалобы пропущены' : 'Жалобы удалены')})
            return true
        } else {
            addNotify({status: 'error', msg: res.data.error})
            return false
        }
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
const getMaxComplaintsCount = async(status) => {
    try {
        let fields = {
            limit: 1000,
            status: parseInt(status),
            offset: 0
        }
        const comments = axios.post('admin/getCommentsComplaints', fields, authHeader)
        const profiles = axios.post('admin/getUsersComplaints', fields, authHeader)
		const posts = axios.post('admin/getPostsComplaints', fields, authHeader)
		const stories = axios.post('admin/getStoriesComplaints', fields, authHeader)
        const storiesBlocks = axios.get('admin/stories/block', {
			params: {
				limit: 200,
				lastId: '0',
				firstId: '0'
			},
			...authHeader
		})
        const postsBlocks = axios.get('admin/posts/block', {
			params: {
				limit: 20,
				lastId: '0',
				firstId: '0'
			},
			...authHeader
		})
        return Promise.all([comments, profiles, posts, stories, storiesBlocks, postsBlocks]).then((values) => {
            return values.map((el, index) => {
                let items = null
                if (el.data.data.complaints) items = el.data.data.complaints;
                if (el.data.data.posts) items = el.data.data.posts;
                if (el.data.data.stories) items = el.data.data.stories;

                let name = '';
                let count = 0;
                switch (index) {
                    case 0: 
                        name = 'complaintscomments';
                        count = el.data.data.complaints ? Math.max.apply(null, el.data.data.complaints.map(comp => comp.complaints.length)) : 0
                        break;
                    case 1:
                        name = 'complaintsprofiles';
                        count = el.data.data.complaints ? Math.max.apply(null, el.data.data.complaints.map(comp => comp.complaints.length)) : 0
                        break;
                    case 2: 
                        name = 'complaintsposts';
                        count = el.data.data.complaints ? Math.max.apply(null, el.data.data.complaints.map(comp => comp.complaints.length)) : 0
                        break;
                    // eslint-disable-next-line
                    case 3: 
                        name = 'complaintsstories';
                        count = el.data.data.complaints ? Math.max.apply(null, el.data.data.complaints.map(comp => comp.complaints.length)) : 0
                        break;
                    case 4: 
                        name = 'blockstories';
                        count = el.data.data.stories ? el.data.data.stories.length : 0
                        break;
                    case 5: 
                        console.log(el)
                        name = 'blockposts';
                        count = el.data.data.posts ? el.data.data.posts.length : 0
                        break;
                }
                return {count, name}
            })
        });
          
        
        
        
    } catch (error) {
        addNotify({status: 'error', msg: error})
    }
}
export {
    getPostsComplaintReasons, getProfilesComplaintReasons, changePostsComplaintReasons, changeProfilesComplaintReasons,
    getStoriesComplaints, changeStoriesComplaintStatus,
    getPostsComplaints, changePostComplaintStatus,
    getUsersComplaints, changeProfileComplaintStatus,
    getCommentsComplaints, changeCommentsComplaintStatus,
    getMaxComplaintsCount
}