const paths = [{
    slug: 'version',
    title: 'Версия приложения'
}, {
    slug: 'roles',
    title: 'Управление ролями'
}, {
    slug: 'emails',
    title: 'Настройка email'
},/* {
    slug: 'settings',
    title: 'Настройки'
},*/ {
    slug: 'politic',
    title: 'Политика конфинденциальности'
}, {
    slug: 'terms',
    title: 'Условия использования'
}, {
    slug: 'weights',
    title: 'Веса'
}]
export default paths.map(path => {
    return {
        path: '/settings/'+path.slug,
        component: () => import('@/views/pages/settings/'+path.slug+'Page.vue'),
        meta: {requiresAuth: true, title: path.title}
    }
})