const paths = [
    {
        slug: 'verification',
        title: 'Заявки на верификацию'
    },{
        slug: 'search',
        title: 'Поиск'
    },{
        slug: 'background-subscription',
        title: 'Фоновая подписка'
    },{
        slug: 'blocked',
        title: 'Заблокированные пользователи'
    },
]
export default paths.map(path => {
    return {
        path: '/profiles/'+path.slug,
        component: () => import('@/views/pages/profiles/'+path.slug+'Page.vue'),
        meta: {requiresAuth: true, title: path.title}
    }
})